import {
  Divider,
  Menu,
  MenuItem,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

export default function NavMenu() {
  const auth = useAuthenticator();
  const nav = useNavigate();

  async function signOut() {
    await auth.signOut();
    nav("/");
  }

  return (
    <Menu menuAlign="end">
      <MenuItem onClick={() => nav("/")}>Rooms</MenuItem>
      <MenuItem onClick={() => nav("/about")}>About</MenuItem>
      <Divider />
      <MenuItem onClick={() => nav("/account")}>Your account</MenuItem>
      <MenuItem onClick={() => signOut()}>Logout</MenuItem>
    </Menu>
  );
}
