import {
  Alert,
  Button,
  Flex,
  Heading,
  SwitchField,
  Text,
  TextField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { API, DataStore } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Chart } from "react-google-charts";
import { useSearchParams } from "react-router-dom";
import { def_options } from "./const";
import { EstimateResult } from "./models";
import { APIRetryPOSTExecution, convertResultsToGraph } from "./Utils";

export default function ResultsComponent() {
  const [executing, setExecuting] = useState<boolean>(false);
  const [saveResult, setSaveResult] = useState(true);
  const [processed, setProcessed] = useState<any>(null);
  const [query, setQuery] = useState<string>();
  const inputQuery = useRef<any>();
  const [params, setParams] = useSearchParams();
  const auth = useAuthenticator();
  const [data, setData] = useState<any>([
    ["Class", "Confidence [%]"],
    ["1", 0],
    ["2", 0],
    ["3", 0],
    ["5", 0],
    ["8", 0],
    ["13", 0],
    ["20", 0],
  ]);
  useEffect(() => {
    const init_text = params.get("init") || "";
    if (init_text?.trim()) {
      setQuery(init_text);
      inputQuery.current.value = init_text;
      params.delete("init");
    }
    return () => {};
  }, []);

  const saveToNotebook = async (data: any) => {
    await DataStore.save(
      new EstimateResult({
        text: data.estimates.raw_text || data.estimates.processed_text,
        bestClass: data.estimates.best.class,
        bestClassConfidence: data.estimates.best.confidence,
        result: JSON.stringify(data),
        ownerDisplayEmail: auth.user.attributes?.email || "unknown",
        ownerDisplayName: auth.user.attributes?.name || "unknown",
        visibleForAll: false,
      })
    );
  };

  const executeEstimates = async () => {
    setExecuting(true);
    try {
      const result = await APIRetryPOSTExecution("restapi", "/predict", {
        body: {
          text: query,
        },
      });
      if (!result.estimates) {
        throw Error("Invalid response from function");
      }
      if (result.is_story < 0.8) {
        throw Error(
          `It doesn't look like a real backlog story - got ${Math.round(
            result.is_story * 100
          )}% chances but at least 80% is expected. Provide more details.`
        );
      }
      setProcessed({
        success: true,
        heading: `Best estimate is ${
          result.estimates.best.class
        } with ${Math.round(
          result.estimates.best.confidence * 100
        )}% confidence`,
        message: result.estimates.processed_text,
      });
      const d = convertResultsToGraph(result);
      setData(d);
      setQuery("");
      inputQuery.current.value = "";
      saveResult && saveToNotebook(result);
    } catch ({ message }) {
      inputQuery.current.value = query;
      setProcessed({
        success: false,
        heading: "Failed",
        message: message,
      });
    } finally {
      setExecuting(false);
    }
  };

  return (
    <Flex direction={"column"} gap={"1rem"}>
      <Heading level={5}>Quick estimates</Heading>
      {processed?.success && (
        <Chart
          chartType={isMobile ? "PieChart" : "ColumnChart"}
          data={data}
          options={def_options}
          width={"100%"}
          height={"300px"}
          loader={<Text>Loading</Text>}
        ></Chart>
      )}
      {processed && (
        <Alert
          variation={processed.success ? "success" : "error"}
          isDismissible={false}
          hasIcon={true}
          heading={processed.heading}
        >
          {processed.message}
        </Alert>
      )}

      <Flex
        alignItems={isMobile ? "default" : "flex-end"}
        direction={isMobile ? "column" : "row"}
      >
        <Flex direction={"column"} width={"100%"}>
          <TextField
            label="Story"
            isRequired={true}
            width={"100%"}
            labelHidden={true}
            onChange={(v: any) => setQuery(v.target.value)}
            onKeyUp={(e: any) => e.keyCode === 13 && executeEstimates()}
            ref={inputQuery}
            isDisabled={executing}
            placeholder="Type your story title here"
          />
        </Flex>
        <Button
          variation="primary"
          minWidth={"10rem"}
          onClick={() => executeEstimates()}
          loadingText="Processing..."
          isDisabled={!query?.trim()}
          isLoading={executing}
        >
          Predict
        </Button>
      </Flex>
      <SwitchField
        isDisabled={executing}
        label="Save your next inference result to history"
        isChecked={saveResult}
        onChange={() => setSaveResult(!saveResult)}
      />
    </Flex>
  );
}
