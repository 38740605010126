import {
  Alert,
  Flex,
  Heading,
  Loader,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Room, RoomEstimateResult } from "./models";
import RoomEstimateRunner from "./RoomEstiamteRunner";
import RoomEstimateRecord from "./RoomEstimateRecord";
import useDatastore from "./useDatastore.hook";

export default function RoomView() {
  const { roomID } = useParams();
  const [roomData, setRoomData] = useState<Room>();
  const [error, setError] = useState(false);

  const [records, loaded, loadData] = useDatastore<RoomEstimateResult>(
    RoomEstimateResult,
    (i) => false,
    (i) => false,
    {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
    },
    (p) => p.roomID("eq", roomID || "")
  );

  useEffect(() => {
    let cleaner: () => void = () => null;
    let unsubscriber = DataStore.observeQuery(Room, (p) =>
      p.id("eq", roomID as string)
    ).subscribe((r) => {
      if (r.items.length === 0) {
        setError(true);
        // console.log(r);

        return;
      }
      setError(false);
      setRoomData(r.items[0]);
      cleaner = loadData();
    });
    return () => {
      cleaner();
      unsubscriber.unsubscribe();
    };
  }, []);

  return (
    <Flex direction={"column"} gap="1rem">
      {roomData && (
        <>
          <Heading level={5}>{roomData?.roomName}</Heading>
          {!loaded && <Loader variation="linear" size="small" />}

          <View style={{ marginTop: "1rem" }}>
            <RoomEstimateRunner roomID={roomID || ""} />
          </View>
          <View style={{ marginTop: "1rem" }}>
            {loaded && records.length === 0 && (
              <Text variation="tertiary">
                No records, your estimates will be saved here.
              </Text>
            )}
            <Flex direction={"column"}>
              {records?.map((record) => (
                <RoomEstimateRecord key={record.id} record={record} />
              ))}
            </Flex>
          </View>
        </>
      )}
      {error && <Alert variation="error">No such room</Alert>}
    </Flex>
  );
}
