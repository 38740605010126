import { Flex, Heading, Link, Text } from "@aws-amplify/ui-react";

export default function About() {
  return (
    <Flex direction={"column"} gap="2rem" alignItems={"flex-start"}>
      <Heading level={5}>About</Heading>
      <Text>
        The model is{" "}
        <Link href="https://openreview.net/pdf?id=r1xMH1BtvB" isExternal={true}>
          BERT
        </Link>{" "}
        fine-tuned for story size classification using team's backlog data.
      </Text>
      <Text>
        Training dataset has been later mixed with typical "to-do" data to
        provide additional classification feature, allowing to filter out
        results for sentences other than backlog items.
      </Text>
      <Text>Model is served by serverless backend for cost reduction.</Text>
    </Flex>
  );
}
