import {
  Button,
  Flex,
  Heading,
  SelectField,
  TabItem,
  Tabs,
  Text,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { API, Auth, DataStore } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Comment, EstimateResult, Room, RoomEstimateResult } from "./models";
import { GiMeshNetwork } from "react-icons/gi";
interface IInferFunc {
  name: string;
}
export default function Account() {
  const auth = useAuthenticator();
  const [funcs, setFuncs] = useState<IInferFunc[]>([]);
  const deleteUserData = async () => {
    await DataStore.delete(EstimateResult, (p) =>
      p.ownerDisplayEmail("eq", auth.user.attributes?.email || "")
    );
    await DataStore.delete(Comment, (p) =>
      p.ownerDisplayEmail("eq", auth.user.attributes?.email || "")
    );
    await DataStore.delete(RoomEstimateResult, (p) =>
      p.ownerDisplayEmail("eq", auth.user.attributes?.email || "")
    );
    await DataStore.delete(Room, (p) =>
      p.ownerDisplayEmail("eq", auth.user.attributes?.email || "")
    );
  };

  const deleteUserDataOnly = async () => {
    if (window.confirm(`Delete all your data ?`)) {
      deleteUserData();
    }
  };

  const deleteAccount = async () => {
    if (window.confirm(`Delete account ?`)) {
      try {
        deleteUserData();
        const result = await Auth.deleteUser();
        Auth.signOut({
          global: true,
        });
        window.location.reload();
      } catch (error) {
        console.log("Error deleting user", error);
      }
    }
  };

  const updateConfig = async () => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:func": "test",
    });
  };

  useEffect(() => {
    API.get("inferences", "/functions", {}).then((result) => {
      console.log(result);
      setFuncs(result);
    });
  }, []);

  return (
    <Tabs>
      <TabItem title="Info">
        <Flex
          direction={"column"}
          gap="1rem"
          alignItems={"flex-start"}
          padding={"1rem"}
        >
          <View>
            <Heading level={6}>User name</Heading>
            <Text padding={"0 1rem"}>{auth.user.attributes?.name}</Text>
          </View>
          <View>
            <Heading level={6}>Email</Heading>
            <Text padding={"0 1rem"}>{auth.user.attributes?.email}</Text>
          </View>
        </Flex>
      </TabItem>
      <TabItem title="Manage">
        <Flex
          direction={"column"}
          gap="1rem"
          alignItems={"flex-start"}
          padding={"1rem"}
        >
          <Button onClick={() => deleteUserDataOnly()}>
            Delete all your data
          </Button>
          <Button onClick={() => deleteAccount()}>Delete account</Button>
        </Flex>
      </TabItem>
      <TabItem title="Inference functions">
        <Flex
          direction={"column"}
          gap="1rem"
          alignItems={"flex-start"}
          padding={"1rem"}
        >
          <View>
            <Text>Your inference function is: default</Text>
          </View>
          <View>
            <Heading level={6}>Available functions</Heading>
            {funcs &&
              funcs.map((f) => (
                <Flex gap=".5rem" paddingLeft={"1rem"} alignItems={"center"}>
                  <GiMeshNetwork></GiMeshNetwork>
                  {f.name}
                </Flex>
              ))}
          </View>
        </Flex>
      </TabItem>
    </Tabs>
  );
}
