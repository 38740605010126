import {
  Alert,
  Button,
  Flex,
  Text,
  TextField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { API, DataStore } from "aws-amplify";
import React, { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Chart } from "react-google-charts";
import { FaRegClock } from "react-icons/fa";
import BlocksDecor from "./BlocksDecor";
import { def_options } from "./const";
import { RoomEstimateResult } from "./models";
import { APIRetryPOSTExecution, convertResultsToGraph } from "./Utils";
type Props = {
  roomID: string;
};
export default function RoomEstimateRunner({ roomID }: Props) {
  const [executing, setExecuting] = useState<boolean>(false);
  const [processed, setProcessed] = useState<any>(null);
  const [query, setQuery] = useState<string>();
  const [longExecutionHintVisible, setLongExecutionHintVisible] =
    useState(false);
  const inputQuery = useRef<any>();
  const auth = useAuthenticator();
  const [data, setData] = useState<any>([
    ["Class", "Confidence [%]"],
    ["1", 0],
    ["2", 0],
    ["3", 0],
    ["5", 0],
    ["8", 0],
    ["13", 0],
    ["20", 0],
  ]);

  const saveToRoom = async (data: any) => {
    await DataStore.save(
      new RoomEstimateResult({
        text: data.estimates.raw_text || data.estimates.processed_text,
        bestClass: data.estimates.best.class,
        bestClassConfidence: data.estimates.best.confidence,
        result: JSON.stringify(data),
        ownerDisplayEmail: auth.user.attributes?.email || "unknown",
        ownerDisplayName: auth.user.attributes?.name || "unknown",
        roomID: roomID,
      })
    );
  };

  const executeEstimates = async () => {
    setExecuting(true);
    setProcessed(false);
    const timeout = setTimeout(() => {
      setLongExecutionHintVisible(true);
    }, 2000);
    try {
      const result = await APIRetryPOSTExecution("restapi", "/predict", {
        body: {
          text: query,
        },
      });
      if (!result.estimates) {
        throw Error("Invalid response from function");
      }
      if (result.is_story < 0.8) {
        throw Error(
          `It doesn't look like a real backlog story - got ${Math.round(
            result.is_story * 100
          )}% chances but at least 80% is required. Provide more details.`
        );
      }
      setProcessed({
        success: true,
        heading: `Best estimate is ${
          result.estimates.best.class
        } with ${Math.round(
          result.estimates.best.confidence * 100
        )}% confidence`,
        message: result.estimates.raw_text || result.estimates.processed_text,
      });
      const d = convertResultsToGraph(result);
      setData(d);
      setQuery("");
      inputQuery.current.value = "";
      saveToRoom(result);
    } catch (e: any) {
      // console.log("error", JSON.stringify(e));

      inputQuery.current.value = query;
      setProcessed({
        success: false,
        heading: "Failed",
        message: e?.message || "unknown error",
      });
    } finally {
      clearTimeout(timeout);
      setLongExecutionHintVisible(false);
      setExecuting(false);
    }
  };

  return (
    <Flex direction={"column"} gap={"1rem"}>
      {processed?.success && (
        <Chart
          chartType={isMobile ? "PieChart" : "ColumnChart"}
          data={data}
          options={def_options}
          width={"100%"}
          height={"300px"}
          loader={<Text>Loading</Text>}
        ></Chart>
      )}
      {processed && (
        <Alert
          variation={processed.success ? "success" : "error"}
          isDismissible={false}
          hasIcon={true}
          heading={processed.heading}
        >
          {processed.message}
        </Alert>
      )}
      {longExecutionHintVisible && (
        <Alert variation="info" heading="Warming up!">
          Inference function is warming up...
        </Alert>
      )}
      <Flex
        alignItems={isMobile ? "default" : "flex-end"}
        direction={isMobile ? "column" : "row"}
      >
        <Flex direction={"column"} width={"100%"}>
          <TextField
            label="Story"
            isRequired={true}
            width={"100%"}
            labelHidden={true}
            onChange={(v: any) => setQuery(v.target.value)}
            onKeyUp={(e: any) => e.keyCode === 13 && executeEstimates()}
            ref={inputQuery}
            isDisabled={executing}
            placeholder="Type your story title here"
          />
        </Flex>
        <Button
          variation="primary"
          minWidth={"10rem"}
          onClick={() => executeEstimates()}
          loadingText="Processing..."
          isDisabled={!query?.trim()}
          isLoading={executing}
        >
          Add story
        </Button>
      </Flex>
    </Flex>
  );
}
