import {
  Flex,
  Heading,
  Text,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Decor from "./Decor";
import NavMenu from "./NavMenu";

export default function Layout({ children }: any) {
  const auth = useAuthenticator();
  const nav = useNavigate();

  return (
    <Flex direction={"column"} gap={"1rem"} margin="auto" maxWidth="1200px">
      <Flex
        justifyContent={"space-between"}
        padding="1rem 1rem"
        position={"sticky"}
        top={"0"}
        backgroundColor={"#fff"}
        style={{ zIndex: "1000" }}
        boxShadow={isMobile ? "0px 2px 8px rgba(0,0,0,0.2)" : "none"}
      >
        <Flex direction={"column"}>
          <Flex
            alignItems={"center"}
            onClick={() => nav("/")}
            style={{ cursor: "pointer" }}
          >
            <Decor></Decor>
            <View>
              <Heading level={isMobile ? 4 : 3}>Estimate Stories</Heading>
              <Text fontSize={"0.9rem"} color="#888">
                Hello {auth.user.attributes?.name}
              </Text>
            </View>
          </Flex>
        </Flex>
        <Flex alignItems={"center"}>
          <NavMenu></NavMenu>
        </Flex>
      </Flex>
      <View margin={"1rem"}>{children}</View>
    </Flex>
  );
}
