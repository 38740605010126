// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { EstimateResult, Comment, RoomEstimateResult, Room } = initSchema(schema);

export {
  EstimateResult,
  Comment,
  RoomEstimateResult,
  Room
};