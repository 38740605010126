export const def_options = {
  fontName: "inherit",
  legend: "none",
  fontSize: 18,
  is3D: true,
  title: "",
  pieSliceText: "label",
  colors: [
    "#176ba0",
    "#1ac9e6",
    "#facd38",
    "#ef7e32",
    "#eb4cc2",
    "#c02323",
    "#6d2ac1",
  ],
  chartArea: { width: "90%", height: "80%" },
  enableInteractivity: false,
};
