import { API } from "aws-amplify";
import { def_options } from "./const";
import { AIEstimateResult } from "./types";

export function convertResultsToGraph(result: AIEstimateResult) {
  return [
    ["Class", "Confidence [%]", { role: "style" }],
    ...result.estimates.classes.map((cls: any, idx: number) => [
      cls.class,
      Math.round(cls.confidence * 100),
      def_options.colors[idx],
    ]),
  ];
}
const MAX_ATTEMPTS = 5;
export async function APIRetryPOSTExecution(
  apiname: string,
  path: string,
  init: {},
  numRetry: number = 0
): Promise<any> {
  if (numRetry > MAX_ATTEMPTS) {
    throw {
      message: `Error processing server request - max ${MAX_ATTEMPTS} attempts failed!`,
    };
  }
  try {
    return await API.post(apiname, path, init);
  } catch (err: any) {
    // if ([400].includes(err.response.status)) {
    return await APIRetryPOSTExecution(apiname, path, init, numRetry + 1);
    // }
  }
}

export function replaceEmoji(text: string): string {
  return text
    .replaceAll(":)", "😀")
    .replaceAll(":P", "😛")
    .replaceAll(";)", "😉")
    .replaceAll(":D", "😁")
    .replaceAll("<3", "💕")
    .replaceAll("XD", "😆");
}
