import {
  PersistentModel,
  PersistentModelConstructor,
  ProducerModelPredicate,
  ProducerPaginationInput,
} from "@aws-amplify/datastore";
import { PredicateAll } from "@aws-amplify/datastore/lib-esm/predicates";
import { DataStore } from "aws-amplify";
import { useRef, useState } from "react";
export enum InsertionDirection {
  TOP,
  BOTTOM,
}
export default function useDatastore<T extends PersistentModel>(
  modelConstructor: PersistentModelConstructor<T>,
  udpateAsDelete: (record: T) => boolean = () => false,
  ommitInserts: (record: T) => boolean = () => false,
  sorting?: ProducerPaginationInput<T>,
  filterPredicate?: ProducerModelPredicate<T> | typeof PredicateAll,
  insertAt: InsertionDirection = InsertionDirection.TOP
): [T[], boolean, () => () => void] {
  const [records, setRecords] = useState<T[]>([]);
  const [loaded, setLoaded] = useState(false);
  const recordsRef = useRef<T[]>([]);
  var sub: any = null;

  const loadRecords = async () => {
    const result = await DataStore.query(
      modelConstructor,
      filterPredicate,
      sorting
    );
    recordsRef.current = result;
    setRecords(recordsRef.current);
  };

  const startSubscription = async () => {
    sub && sub.unsubscribe();
    const fiterSub: ProducerModelPredicate<T> | undefined =
      filterPredicate === PredicateAll
        ? undefined
        : (filterPredicate as ProducerModelPredicate<T>);
    const subscription = DataStore.observe(
      modelConstructor,
      fiterSub
    ).subscribe((msg) => {
      let index = recordsRef.current.findIndex(
        (elem) => elem.id === msg.element.id
      );
      let newRecords = [...recordsRef.current];
      switch (msg.opType) {
        case "DELETE":
          if (index !== -1) {
            newRecords = newRecords.filter((e) => e.id !== msg.element.id);
            recordsRef.current = newRecords;
          }
          break;
        case "UPDATE":
          if (udpateAsDelete && udpateAsDelete(msg.element)) {
            newRecords = newRecords.filter((e) => e.id !== msg.element.id);
            recordsRef.current = newRecords;
          } else {
            if (index !== -1) {
              newRecords[index] = msg.element;
              recordsRef.current = newRecords;
            } else {
              if (insertAt === InsertionDirection.TOP) {
                recordsRef.current = [msg.element, ...recordsRef.current];
              } else {
                recordsRef.current = [...recordsRef.current, msg.element];
              }
            }
          }
          break;
        case "INSERT":
          if (index === -1 && !(ommitInserts && ommitInserts(msg.element))) {
            if (insertAt === InsertionDirection.TOP) {
              recordsRef.current = [msg.element, ...recordsRef.current];
            } else {
              recordsRef.current = [...recordsRef.current, msg.element];
            }
          }
          break;
        default:
          break;
      }
      setRecords(recordsRef.current);
    });
    return subscription;
  };

  const loadData = () => {
    var cancelSub: () => void = () => {
      sub && sub.unsubscribe();
    };
    loadRecords().then(() => {
      startSubscription().then((s) => {
        sub = s;
      });
      setLoaded(true);
    });
    return cancelSub;
  };

  return [records, loaded, loadData];
}
