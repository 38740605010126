import {
  Badge,
  Card,
  Flex,
  Link,
  Loader,
  Text,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import { isMobile } from "react-device-detect";
import { FaClock, FaTrashAlt, FaUserTag } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import BlocksDecor from "./BlocksDecor";
import { Room } from "./models";
import "./FontColors.css";

type Props = { record: Room };
export default function Note({ record }: Props) {
  const nav = useNavigate();
  const auth = useAuthenticator();

  const deleteRecord = async (record: Room) => {
    if (window.confirm("Delete room?")) {
      await DataStore.delete(record);
    }
  };

  return (
    <Card
      backgroundColor={"#f4f4f4"}
      // variation={"outlined"}
      borderRadius={"4px"}
      minWidth={isMobile ? "auto" : "600px"}
    >
      <Flex alignItems={"center"}>
        <Flex
          justifyContent={"space-between"}
          direction={"column"}
          grow="1"
          overflow={"hidden"}
        >
          <View>
            <Link fontWeight={"500"} onClick={() => nav(`/room/${record.id}`)}>
              {record.roomName}
            </Link>
          </View>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex alignItems={"center"}>
              {record.createdAt ? (
                <Badge fontWeight={"600"} size="small">
                  <FaClock size={"0.8rem"} style={{ marginRight: "0.5rem" }} />
                  {record.createdAt &&
                    new Date(record.createdAt).toLocaleString()}
                </Badge>
              ) : (
                <Loader size="small" />
              )}
              {record.ownerDisplayEmail !== auth.user.attributes?.email && (
                <Badge fontWeight={"600"} size="small">
                  <FaUserTag
                    size={"0.8rem"}
                    style={{ marginRight: "0.5rem" }}
                  />
                  {record.ownerDisplayName}
                </Badge>
              )}
            </Flex>
            <Flex>
              {record.ownerDisplayEmail === auth.user.attributes?.email && (
                <Link
                  title="Delete"
                  onClick={() => deleteRecord(record)}
                  className={"danger-link"}
                >
                  <FaTrashAlt />
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
