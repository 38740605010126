import {
  Alert,
  Badge,
  Button,
  Flex,
  Heading,
  Loader,
  Text,
  TextField,
  useAuthenticator,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import { DataStore, SortDirection } from "aws-amplify";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import Chart from "react-google-charts";
import {
  FaArrowCircleRight,
  FaClock,
  FaEnvelopeOpenText,
  FaUserTag,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { def_options } from "./const";
import Decor from "./Decor";
import { Comment, RoomEstimateResult } from "./models";
import { AIEstimateResult } from "./types";
import useDatastore, { InsertionDirection } from "./useDatastore.hook";
import { convertResultsToGraph, replaceEmoji } from "./Utils";

export default function RoomEstimateRecordChat() {
  const [processed, setProcessed] = useState<any>(null);
  const auth = useAuthenticator();
  const [record, setRecord] = useState<AIEstimateResult>();
  const [fullRecord, setFullRecord] = useState<RoomEstimateResult>();
  const { recordID } = useParams();
  const containerRef = useRef<any>();
  const [error, setError] = useState(false);
  const [commentInput, setCommentInput] = useState<string>();
  const showIcons = useBreakpointValue({
    base: false,
    small: true,
  });
  const newCommentRref = useRef<any>();
  const [data, setData] = useState<any>([
    ["Class", "Confidence [%]"],
    ["1", 0],
    ["2", 0],
    ["3", 0],
    ["5", 0],
    ["8", 0],
    ["13", 0],
    ["20", 0],
  ]);
  const [comments, loaded, loadData] = useDatastore<Comment>(
    Comment,
    (i) => false,
    (i) => false,
    {
      sort: (s) => s.createdAt(SortDirection.ASCENDING),
    },
    (p) => p.roomestimateresultID("eq", recordID || ""),
    InsertionDirection.BOTTOM
  );

  useLayoutEffect(() => {
    scrollDown();
  }, [comments]);

  useEffect(() => {
    let cleaner: () => void = () => null;
    const unsubscriber = DataStore.observeQuery(RoomEstimateResult, (p) =>
      p.id("eq", recordID as string)
    ).subscribe((r) => {
      if (r.items.length === 0) {
        setError(true);
        return;
      }
      setError(false);
      setFullRecord(r.items[0]);
      const result = JSON.parse(r.items[0]?.result || "") as AIEstimateResult;
      setRecord(result);
      const d = convertResultsToGraph(result);
      setData(d);
      setProcessed({
        success: true,
        heading: `Best estimate is ${
          result.estimates.best.class
        } with ${Math.round(
          result.estimates.best.confidence * 100
        )}% confidence`,
        message: result.estimates.processed_text,
      });
      cleaner = loadData();
    });
    return () => {
      cleaner();
      unsubscriber.unsubscribe();
    };
  }, []);

  const isEmojiOnly = (text: string): boolean => {
    const emojiRegex = /^[\p{Emoji}]$/u;

    return emojiRegex.test(text.trim());
  };

  const addComment = async () => {
    if (!commentInput?.trim()) {
      return;
    }
    await DataStore.save(
      new Comment({
        roomestimateresultID: recordID || "",
        text: replaceEmoji(commentInput?.trim() || ""),
        ownerDisplayEmail: auth.user.attributes?.email || "",
        ownerDisplayName: auth.user.attributes?.name || "",
      })
    );
    setCommentInput("");
    newCommentRref.current.value = "";
    scrollDown(true);
    newCommentRref.current.focus();
  };

  const scrollDown = (force: boolean = false) => {
    const containeR = containerRef.current;
    const scrollPct = (100 * window.scrollY) / containeR.scrollHeight;
    (scrollPct > 1 || force) && window.scrollTo(0, Number.MAX_SAFE_INTEGER);
  };

  return (
    <Flex direction={"column"} ref={containerRef}>
      {record && (
        <>
          <Heading level={isMobile ? 5 : 4}>
            {record?.estimates.raw_text}
          </Heading>
          <Flex>
            <Badge>
              <FaClock size={"1rem"} style={{ marginRight: "0.5rem" }} />
              {fullRecord?.createdAt &&
                new Date(fullRecord.createdAt).toLocaleString()}
            </Badge>
            <Badge fontWeight={"600"}>
              <FaUserTag size={"1rem"} style={{ marginRight: "0.5rem" }} />
              {fullRecord?.ownerDisplayName}
            </Badge>
          </Flex>
        </>
      )}
      {record && (
        <Flex direction={"column"}>
          <Flex direction={"column"}>
            <Alert
              variation={processed.success ? "success" : "error"}
              isDismissible={false}
              hasIcon={true}
              heading={`Best ${record.estimates.best.class} (${Math.round(
                record.estimates.best.confidence * 100
              )}%)`}
            />

            <Chart
              chartType={isMobile ? "PieChart" : "ColumnChart"}
              data={convertResultsToGraph(record)}
              options={def_options}
              width={"100%"}
              height={"300px"}
              loader={<Text>Loading</Text>}
            ></Chart>

            {/* <Decor
              chartdata={record?.estimates}
              showClasses={true}
              style={{ height: "300px" }}
            ></Decor> */}
          </Flex>
          <Flex direction={"column"}>
            <Flex direction={"column"} style={{ position: "relative" }}>
              {comments.map((c) => (
                <Flex
                  key={c.id}
                  gap="0"
                  direction="column"
                  alignSelf={
                    c.ownerDisplayEmail === auth.user.attributes?.email
                      ? "flex-end"
                      : "flex-start"
                  }
                  maxWidth={"80%"}
                  backgroundColor={
                    c.ownerDisplayEmail === auth.user.attributes?.email
                      ? "cornsilk"
                      : "aliceblue"
                  }
                  borderRadius=".5rem"
                >
                  <Flex
                    fontSize={"0.9rem"}
                    padding={".5rem"}
                    gap="0.25rem"
                    justifyContent="space-between"
                    direction={{ base: "column", small: "row" }}
                  >
                    <Flex
                      alignItems={"center"}
                      gap=".5rem"
                      marginRight={"0.5rem"}
                    >
                      {showIcons && <FaEnvelopeOpenText size={"0.9rem"} />}
                      <Text fontWeight={"600"}>{c.ownerDisplayName}</Text>
                    </Flex>

                    <Flex alignItems={"center"} gap="0.25rem">
                      {c.createdAt ? (
                        <Text fontWeight={"200"}>
                          {new Date(c.createdAt).toLocaleString()}
                        </Text>
                      ) : (
                        <Loader></Loader>
                      )}
                    </Flex>
                  </Flex>
                  <Text
                    padding={"0 0.5rem 1rem 0.5rem "}
                    fontSize={isEmojiOnly(c.text) ? "3rem" : "inherit"}
                  >
                    {c.text}
                  </Text>
                </Flex>
              ))}
              <Flex bottom="0rem" backgroundColor={"white"} padding="0.5rem 0">
                <TextField
                  label="Comment"
                  isRequired={true}
                  width={"100%"}
                  labelHidden={true}
                  autoComplete="off"
                  ref={newCommentRref}
                  onChange={(v: any) => setCommentInput(v.target.value)}
                  onKeyUp={(e: any) => e.keyCode === 13 && addComment()}
                  placeholder="Your comment"
                />
                <Button variation="primary" onClick={() => addComment()}>
                  <FaArrowCircleRight />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      {error && <Alert variation="error">No such record</Alert>}
    </Flex>
  );
}
