import "@aws-amplify/ui-react/styles.css";
import { Amplify, API, DataStore, Hub, I18n } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { IconContext } from "react-icons";
import App from "./App";
import awsExports from "./aws-exports";
import reportWebVitals from "./reportWebVitals";
import "./theme.css";

Amplify.configure(awsExports);
Hub.listen("auth", async (data) => {
  if (data.payload.event === "signOut") {
    console.log("clearing local store - auth event:", data.payload.event);
    try {
      await DataStore.clear();
    } catch (e) {
      console.error("Error when clearing datastore:", e);
    }
  }
});

DataStore.setErrorHandler({
  errorHandler: (error) => {
    console.log("datastore error, executig clear");
    DataStore.clear();
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <IconContext.Provider value={{ size: "1.2rem" }}>
      <App />
    </IconContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
