import {
  Authenticator,
  Flex,
  Heading,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import About from "./About";
import Account from "./Account";
import Decor from "./Decor";
import E404 from "./E404";
import Layout from "./Layout";
import ResultsComponent from "./ResultComponent";
import RoomEstimateRecordChat from "./RoomEstiamteRecordChat";
import Rooms from "./Rooms";
import RoomView from "./RoomView";

function App() {
  const comps: any = {
    Header: () => (
      <View margin="2rem 0">
        <Flex alignItems={"center"}>
          <Decor></Decor>
          <Heading level={1} margin="1rem 0">
            Hello!
          </Heading>
        </Flex>
        <Text>Welcome to AI scrum story sizing experiment.</Text>
        <Text>Please sign-in or register using corporate email.</Text>
      </View>
    ),
  };

  return (
    <Authenticator components={comps}>
      <HashRouter>
        <Layout>
          <Routes>
            <Route path="estimate" element={<ResultsComponent />} />
            <Route path="about" element={<About />} />
            <Route index={true} element={<Rooms />} />
            <Route path="room/:roomID" element={<RoomView />} />
            <Route
              path="/record/:recordID"
              element={<RoomEstimateRecordChat />}
            />
            <Route path="account" element={<Account />} />
            <Route path="*" element={<E404 />} />
          </Routes>
        </Layout>
      </HashRouter>
    </Authenticator>
  );
}

export default App;
