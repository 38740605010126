import {
  Button,
  Flex,
  Heading,
  Loader,
  Text,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect } from "react";
import { Room } from "./models";
import RoomRecord from "./RoomRecord";
import useDatastore from "./useDatastore.hook";

export default function Rooms() {
  const auth = useAuthenticator();

  const [records, loaded, loadData] = useDatastore<Room>(
    Room,
    (i) => false,
    (i) => false,
    {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
    },
    Predicates.ALL
  );

  useEffect(() => {
    return loadData();
  }, []);

  const createRoom = () => {
    const name = window.prompt("room name?");
    if (name) {
      DataStore.save(
        new Room({
          roomName: name,
          ownerDisplayEmail: auth.user.attributes?.email || "",
          ownerDisplayName: auth.user.attributes?.name || "",
        })
      );
    }
  };

  return (
    <Flex direction={"column"} gap="1rem">
      <Flex justifyContent={"space-between"}>
        <Heading level={5}>Team rooms</Heading>
        {!loaded && <Loader variation="linear" size="small" />}
        {loaded && (
          <Button variation="link" onClick={() => createRoom()}>
            Add
          </Button>
        )}
      </Flex>
      {loaded && records.length === 0 && (
        <Text variation="tertiary">Rooms will appear here.</Text>
      )}
      <Flex direction={"column"}>
        {records?.map((record) => (
          <RoomRecord key={record.id} record={record} />
        ))}
      </Flex>
    </Flex>
  );
}
