import React, { useMemo } from "react";
import { def_options } from "./const";
import "./Decor.css";
import { AIEstimateResult, AIEstimates } from "./types";

const delays = def_options.colors.map(
  () => (Math.random() * 1.5).toFixed(3) + "s"
);
type Props = {
  chartdata?: AIEstimates;
  style?: any;
  onClick?: any;
  showClasses?: boolean;
};
export default function Decor({
  chartdata,
  style,
  onClick,
  showClasses,
}: Props) {
  const normalizer: number = useMemo<number>((): number => {
    return !chartdata
      ? 1
      : chartdata.classes.map((c) => c.confidence).reduce((s, c) => (s += c)) +
          1e-4; //epsilon to avoid div by 0
  }, []);
  return (
    <div
      onClick={onClick}
      style={style}
      className={
        chartdata
          ? showClasses
            ? "decor decor-bordered decor-with-labels"
            : "decor decor-bordered"
          : "decor"
      }
    >
      {chartdata && (
        <div className="decor-stats">
          {chartdata.best.class} ({Math.round(chartdata.best.confidence * 100)}
          %)
        </div>
      )}
      {def_options.colors.map((c, idx) =>
        chartdata ? (
          <div
            className={"decor-item-chart"}
            key={c}
            style={{
              backgroundColor: c,
              animationDelay: delays[idx],
              height: `${
                Math.ceil(
                  chartdata.classes[idx].confidence * (1 / normalizer) * 92
                ) + 2
              }%`,
            }}
          >
            {showClasses && (
              <div className="decor-item-chart-class">
                {chartdata.classes[idx].class}
              </div>
            )}
          </div>
        ) : (
          <div
            className={"decor-item"}
            key={c}
            style={{
              backgroundColor: c,
              animationDelay: delays[idx],
            }}
          ></div>
        )
      )}
    </div>
  );
}
